import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MenuItem, MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MatIconModule} from '@angular/material/icon';
import {AgreementEditDialogComponent} from '../../dialogs/agreement-edit-dialog/agreement-edit-dialog.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaCardComponent, SafeTranslatePipe, MatIconModule],
  selector: 'app-agreement-data',
  standalone: true,
  templateUrl: './agreement-data.component.html'
})
export class AgreementDataComponent {
  menuItems: Array<MenuItem> = Array({
    menuItemTitle: 'i18n.profile-settings.consent.menu.edit-consent',
    component: AgreementEditDialogComponent
  });
}
