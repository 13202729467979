import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {TranslateModule} from '@ngx-translate/core';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MsaCardComponent,
    SafeTranslatePipe,
    TranslateObjectPipe,
    StatusPillComponent,
    TranslateModule
  ],
  selector: 'app-military-current',
  standalone: true,
  templateUrl: './military-current.component.html'
})
export class MilitaryCurrentComponent {
  currentMilitaryData = readStoreSignal(PersonDataStateSelectors.getCurrentMilitaryData);
}
