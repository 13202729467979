import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {UpdateBankAccountData} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {ibanValidator} from '@shared/shared-module/validators/iban.validator';
import {provideNgxMask} from 'ngx-mask';
import {BankAccountChangeDto} from '../../core/api/generated/msa-person-data';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {MsaTextInputComponent} from '@shared/shared-module/components/msa-text-input/msa-text-input.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, MsaTextInputComponent, MsaCheckboxComponent, SafeTranslatePipe, ReactiveFormsModule],
  providers: [provideNgxMask()],
  selector: 'msa-iban-edit-dialog',
  standalone: true,
  templateUrl: './iban-edit-dialog.component.html'
})
export class IbanEditDialogComponent {
  private store: Store = inject(Store);
  private destroyRef: DestroyRef = inject(DestroyRef);
  personalData = readStoreSignal(PersonDataStateSelectors.getTopBarData);
  fullName = computed(() => this.personalData()?.firstName + ' ' + this.personalData()?.lastName);

  ibanForm: FormGroup = new FormGroup({
    iban: new FormControl('', [Validators.required, Validators.maxLength(26), ibanValidator()]),
    userIsAccountOwner: new FormControl(true)
  });

  ownerForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.maxLength(19)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(29)]),
    addressLine1: new FormControl('', [Validators.required, Validators.maxLength(55)]),
    addressLine2: new FormControl('', [Validators.maxLength(55)]),
    zipCode: new FormControl('', [Validators.required, Validators.maxLength(12)]),
    city: new FormControl('', [Validators.required, Validators.maxLength(30)])
  });

  updateBankAccount($event: DialogConfirmEvent): void {
    const bankAccountChangeDto: BankAccountChangeDto = {
      iban: this.ibanForm.value.iban.toUpperCase().replace(/\s/g, ''),
      userIsAccountOwner: this.ibanForm.value.userIsAccountOwner,
      owner: !this.ibanForm.value.userIsAccountOwner ? this.ownerForm.value : null
    };
    this.store
      .dispatch(new UpdateBankAccountData(bankAccountChangeDto))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: $event.resolve,
        error: $event.reject
      });
  }
}
