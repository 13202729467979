import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, effect, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {Store} from '@ngxs/store';
import {
  AutocompleteOption,
  MsaAutocompleteComponent
} from '@shared/shared-module/components/msa-autocomplete/msa-autocomplete.component';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {FetchCodeListLanguages} from '@shared/shared-module/stores/actions/code-list.action';
import {UpdateLanguageSkills} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {CodeListSelectors} from '@shared/shared-module/stores/selectors/code-list.selectors';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {
  LanguageSkillsDto,
  LanguageSkillsDtoSkillLevelReadEnum,
  LanguageSkillsDtoSkillLevelTalkEnum,
  LanguageSkillsDtoSkillLevelWriteEnum,
  LanguageSkillUpdateDto
} from '../../core/api/generated/msa-person-data';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {MessageType} from '@shared/shared-module/components/enums/messageType';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MsaDialogComponent,
    ReactiveFormsModule,
    MatSelectModule,
    SafeTranslatePipe,
    MsaAutocompleteComponent,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MsaCheckboxComponent
  ],
  selector: 'msa-language-skills-edit-dialog',
  standalone: true,
  templateUrl: './language-skills-edit-dialog.component.html'
})
export class LanguageSkillsEditDialogComponent {
  protected readonly LanguageSkillsDtoSkillLevelTalkEnum = LanguageSkillsDtoSkillLevelTalkEnum;
  protected readonly LanguageSkillsDtoSkillLevelReadEnum = LanguageSkillsDtoSkillLevelReadEnum;
  protected readonly LanguageSkillsDtoSkillLevelWriteEnum = LanguageSkillsDtoSkillLevelWriteEnum;
  private readonly store: Store = inject(Store);
  private readonly changeDetectionRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly languageSkills = readStoreSignal(PersonDataStateSelectors.getLanguageSkills);
  private readonly snackbarService = inject(SnackbarService);
  languages = readStoreSignal(CodeListSelectors.getLanguagesAutocompleteList);

  filteredLanguages(idx: number): Array<AutocompleteOption> {
    const availableLanguages = this.languages();
    const selectedLanguagesFormIds = this.languageSkillsForm.controls.languageSkills.value.map(
      (skill: {language: AutocompleteOption | null}) => skill.language?.id
    );
    const currentSelectedLanguageId = this.languageSkillsForm.controls.languageSkills.at(idx).value.language?.id;

    return availableLanguages.filter(
      language => !selectedLanguagesFormIds.includes(language.id) || language.id === currentSelectedLanguageId
    );
  }

  uniqueLanguageValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormArray)) {
      return null;
    }

    const languages = control.controls.map(group => group.get('language')?.value?.id).filter(id => !!id);
    const uniqueLanguages = new Set<string>();
    const duplicateLanguages = new Set<string>();

    languages.forEach(languageId => {
      if (uniqueLanguages.has(languageId)) {
        duplicateLanguages.add(languageId);
      } else {
        uniqueLanguages.add(languageId);
      }
    });

    control.controls.forEach(group => {
      const languageControl = group.get('language');
      const languageId = languageControl?.value?.id;

      if (languageId && duplicateLanguages.has(languageId)) {
        languageControl?.setErrors({duplicateLanguage: true});
      } else {
        const controlErrors = languageControl?.errors;
        if (controlErrors && 'duplicateLanguage' in controlErrors) {
          delete controlErrors['duplicateLanguage'];
          languageControl?.setErrors(Object.keys(controlErrors).length > 0 ? controlErrors : null);
        }
      }
    });

    return duplicateLanguages.size > 0 ? {nonUniqueLanguages: true} : null;
  };

  languageSkillsForm = new FormGroup({languageSkills: new FormArray<FormGroup>([], [this.uniqueLanguageValidator])});

  constructor() {
    this.store.dispatch(new FetchCodeListLanguages());

    effect(() => {
      this.languageSkillsForm = new FormGroup({
        languageSkills: new FormArray<FormGroup>(
          this.languageSkills()?.map(languageSkill => this.createLanguageSkillGroup(languageSkill)) ?? [],
          [this.uniqueLanguageValidator]
        )
      });
      this.changeDetectionRef.detectChanges();
    });
  }

  createLanguageSkillGroup(languageSkill: LanguageSkillsDto | undefined) {
    const selectedLanguage = this.languages()?.find(value => value.id === languageSkill?.language?.codeHash);

    return new FormGroup({
      language: new FormControl(selectedLanguage),
      motherTongue: new FormControl(languageSkill?.motherTongue ?? false),
      skillLevelTalk: new FormControl(languageSkill?.skillLevelTalk ?? LanguageSkillsDtoSkillLevelTalkEnum.Low),
      skillLevelRead: new FormControl(languageSkill?.skillLevelRead ?? LanguageSkillsDtoSkillLevelReadEnum.Low),
      skillLevelWrite: new FormControl(languageSkill?.skillLevelWrite ?? LanguageSkillsDtoSkillLevelWriteEnum.Low)
    });
  }

  addLanguageSkill(): void {
    this.languageSkillsForm.controls.languageSkills.push(this.createLanguageSkillGroup(undefined));
  }

  removeLanguageSkill($index: number) {
    this.languageSkillsForm.controls.languageSkills.removeAt($index);
  }

  updateLanguageSkills($event: DialogConfirmEvent) {
    this.languageSkillsForm.controls.languageSkills.controls.forEach(control => {
      const languageControl = control.get('language');
      languageControl?.setValidators(Validators.required);
      languageControl?.updateValueAndValidity();
    });

    if (this.languageSkillsForm.valid) {
      const payload: Array<LanguageSkillUpdateDto> = [];
      this.languageSkillsForm.controls.languageSkills.value.forEach(languageSkill => {
        const languageSkillUpdateDto: LanguageSkillUpdateDto = {
          languageCodeHash: languageSkill.language.id,
          skillLevelTalk: languageSkill.skillLevelTalk,
          skillLevelRead: languageSkill.skillLevelRead,
          skillLevelWrite: languageSkill.skillLevelWrite,
          motherTongue: languageSkill.motherTongue
        };
        payload.push(languageSkillUpdateDto);
      });
      this.store
        .dispatch(new UpdateLanguageSkills(payload))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({next: $event.resolve, error: $event.reject});
    } else {
      this.snackbarService.openSnackbar({text: 'i18n.common.error.generic', type: MessageType.Error});
    }
  }
}
