<div class="tw-mb-60 tw-mt-30 tw-flex tw-flex-col tw-gap-30 d:tw-mb-120 d:tw-mt-60 d:tw-gap-60">
  <h1 class="msa-text-h1">
    {{ "i18n.contacts.title" | safeTranslate }}
  </h1>

  @if (divisionSuperiorContact()) {
    <msa-superior-contact
      [titleText]="'i18n.contacts.superior-contact.title'"
      [superiorContact]="divisionSuperiorContact()!"
    />
  }
  <msa-contact-links />
</div>
