import {ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MenuItem, MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {FeatureFlagDirective} from '@shared/shared-module/directives/feature-flag.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {environment} from '../../../../../../src/environments/environment';
import {
  CommunicationLanguageType,
  LanguageSkillsDtoSkillLevelReadEnum,
  LanguageSkillsDtoSkillLevelTalkEnum,
  LanguageSkillsDtoSkillLevelWriteEnum
} from '../../core/api/generated/msa-person-data';
import {EmailEditDialogComponent} from '../../dialogs/email-edit-dialog/email-edit-dialog.component';
import {LanguageSkillsEditDialogComponent} from '../../dialogs/language-skills-edit-dialog/language-skills-edit-dialog.component';
import {PhoneEditDialogComponent} from '../../dialogs/phone-edit-dialog/phone-edit-dialog.component';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaCardComponent, MatIconModule, SafeTranslatePipe, TranslateObjectPipe, FeatureFlagDirective],
  selector: 'msa-communication-data',
  standalone: true,
  templateUrl: './communication-data.component.html'
})
export class CommunicationDataComponent {
  protected readonly environment = environment;
  private featureFlagService = inject(FeatureFlagService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  menuItems: Array<MenuItem> = [
    {
      component: PhoneEditDialogComponent,
      menuItemTitle: 'i18n.person-data.dialogs.phone-edit.title'
    },
    {
      component: EmailEditDialogComponent,
      menuItemTitle: 'i18n.person-data.dialogs.email-edit.title'
    }
  ];

  constructor() {
    effect(() => {
      if (this.featureFlagService.hasFeaturesEnabled('languageSkills')()) {
        this.menuItems = [
          ...this.menuItems,
          {
            component: LanguageSkillsEditDialogComponent,
            menuItemTitle: 'i18n.person-data.dialogs.language-skills.title'
          }
        ];
      } else {
        this.menuItems = this.menuItems.filter(item => item.component !== LanguageSkillsEditDialogComponent);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  phoneNumbersByType = readStoreSignal(PersonDataStateSelectors.getCommunicationPhonesByType);
  emailsByType = readStoreSignal(PersonDataStateSelectors.getCommunicationEmailsByType);
  communicationLanguage = readStoreSignal(PersonDataStateSelectors.getCommunicationLanguage);
  languageSkills = readStoreSignal(PersonDataStateSelectors.getLanguageSkills);

  getLanguageSkillLevelTranslation(
    languageSkillLevel:
      | LanguageSkillsDtoSkillLevelTalkEnum
      | LanguageSkillsDtoSkillLevelReadEnum
      | LanguageSkillsDtoSkillLevelWriteEnum
      | undefined
  ): TranslationString {
    switch (languageSkillLevel) {
      case LanguageSkillsDtoSkillLevelTalkEnum.Low:
      case LanguageSkillsDtoSkillLevelReadEnum.Low:
      case LanguageSkillsDtoSkillLevelWriteEnum.Low:
        return 'i18n.person-data.communication.table.value.low';
      case LanguageSkillsDtoSkillLevelTalkEnum.Medium:
      case LanguageSkillsDtoSkillLevelReadEnum.Medium:
      case LanguageSkillsDtoSkillLevelWriteEnum.Medium:
        return 'i18n.person-data.communication.table.value.medium';
      case LanguageSkillsDtoSkillLevelTalkEnum.High:
      case LanguageSkillsDtoSkillLevelReadEnum.High:
      case LanguageSkillsDtoSkillLevelWriteEnum.High:
        return 'i18n.person-data.communication.table.value.high';
      default:
        return 'i18n.common.null';
    }
  }

  getCommunicationLanguageTranslation(communicationLanguage: CommunicationLanguageType): TranslationString {
    switch (communicationLanguage) {
      case CommunicationLanguageType.Ger:
        return 'i18n.language.de';
      case CommunicationLanguageType.Fra:
        return 'i18n.language.fr';
      case CommunicationLanguageType.Ita:
        return 'i18n.language.it';
      default:
        return 'i18n.common.null';
    }
  }
}
