<msa-card
  [noPadding]="true"
  [menuItems]="menuItems()"
  cardTitle="i18n.person-data.swiss-pass.title"
  tooltipTitle="i18n.person-data.swiss-pass.pop-over-title"
  tooltipContent="i18n.person-data.swiss-pass.pop-over-text"
>
  <div class="tw-grid tw-grid-cols-3 d:tw-grid-cols-5">
    <div>
      <div class="tw-grid tw-h-full tw-place-content-center tw-bg-[#E30613] d:tw-me-30">
        <img
          class="tw-p-9 d:tw-p-15"
          ngSrc="assets/images/swisspass/swisspass_logo.svg"
          alt="SwissPass Logo"
          width="218"
          height="146"
        />
      </div>
    </div>
    @if (swissPass()) {
      <div class="tw-col-span-2 tw-my-24 tw-px-15 d:tw-my-30 d:tw-px-30">
        <div class="d:tw-grid d:tw-grid-cols-2">
          <dt class="msa-text tw-text-black-light-5.5">
            {{ "i18n.person-data.swiss-pass.customer-number" | safeTranslate }}
          </dt>
          <dd class="msa-text" data-cy="swissPassCustomerNumber">{{ swissPass().ckm }}</dd>
        </div>
      </div>
    } @else {
      <div
        class="tw-col-span-2 tw-my-24 tw-me-[48px] tw-px-15 d:tw-relative d:tw-my-30 d:tw-me-0 d:tw-border-r d:tw-border-r-black-light-3 d:tw-px-30"
      >
        <div class="tw-relative tw-mb-6 d:tw-mb-18 d:tw-flex">
          <p class="msa-text tw-text-black-light-5.5" data-cy="noSwissSignPassLinked">
            {{ "i18n.person-data.swiss-pass.no-swiss-pass-linked" | safeTranslate }}
          </p>
        </div>
        <button
          (click)="connectSwissPass()"
          style="width: auto"
          color="accent"
          data-cy="connectSwissPass"
          mat-flat-button
        >
          {{ "i18n.person-data.swiss-pass.button-text" | safeTranslate }}
        </button>
      </div>
      <div class="tw-hidden d:tw-col-span-2 d:tw-my-30 d:tw-block d:tw-content-end d:tw-px-30">
        <p class="msa-text tw-mb-6">{{ "i18n.person-data.swiss-pass.link-question" | safeTranslate }}</p>
        <msa-arrow-link
          [label]="'i18n.person-data.swiss-pass.link-to-order'"
          [externalLink]="'https://www.swisspass.ch/home?lang=' + currentLanguage()"
        />
      </div>
    }
  </div>
</msa-card>
