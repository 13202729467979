import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AsyncPipe, NgIf} from '@angular/common';
import {MenuItem, MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {IbanEditDialogComponent} from '../../dialogs/iban-edit-dialog/iban-edit-dialog.component';
import {NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {MatIconModule} from '@angular/material/icon';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, MsaCardComponent, NgIf, SafeTranslatePipe, NgxMaskPipe, MatIconModule],
  providers: [provideNgxMask()],
  selector: 'msa-bank-data',
  standalone: true,
  templateUrl: './bank-data.component.html'
})
export class BankDataComponent {
  menuItems: Array<MenuItem> = Array({
    menuItemTitle: 'i18n.person-data.dialogs.bank-edit.title',
    component: IbanEditDialogComponent
  });
  bankAccountData = readStoreSignal(PersonDataStateSelectors.getBankAccountData);
  bankAccountHolderData = readStoreSignal(PersonDataStateSelectors.getBankAccountHolderData);
}
