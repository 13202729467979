import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PersonDataComponent} from './pages/person-data/person-data.component';
import {authorizationGuard} from '@shared/shared-module/guards/authorization.guard';
import {agreementGuard} from '@shared/shared-module/guards/agreement.guard';
import {ContactsComponent} from './pages/contacts/contacts.component';
import {MilitaryCareerComponent} from './pages/military-career/military-career.component';
import {AppComponent} from '../../../admin-query/src/app/app.component';
import {popupsGuard} from '@shared/shared-module/guards/popups.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [authorizationGuard(), agreementGuard(), popupsGuard],
    component: AppComponent,
    children: [
      {path: '', redirectTo: 'personal', pathMatch: 'full'},
      {path: 'personal', component: PersonDataComponent},
      {path: 'contacts', component: ContactsComponent},
      {path: 'military', component: MilitaryCareerComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModulePersonData {}
