import {NgModule} from '@angular/core';
import {environment} from '../../../../src/environments/environment';

import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModulePersonData} from './app-routing.module';
import {AppStoreModule} from './app-store.module';
import {MsaPersonDataConfiguration, MsaPersonDataConfigurationParameters} from './core/api/generated/msa-person-data';
import {PersonDataComponent} from './pages/person-data/person-data.component';
import {ContactsComponent} from './pages/contacts/contacts.component';
import {MilitaryCareerComponent} from './pages/military-career/military-career.component';

export function msaPersonDataConfigurationFactory(): MsaPersonDataConfiguration {
  const params: MsaPersonDataConfigurationParameters = {
    basePath: environment.api.personDataUrl
  };
  return new MsaPersonDataConfiguration(params);
}

@NgModule({
  imports: [
    AppStoreModule,
    AppRoutingModulePersonData,
    HttpClientModule,
    PersonDataComponent,
    ContactsComponent,
    MilitaryCareerComponent
  ],
  exports: [],
  providers: []
})
export class AppModule {}
