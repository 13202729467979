<msa-card [menuItems]="menuItems" cardTitle="i18n.profile-settings-title.user.bankdata">
  @if (bankAccountData()?.iban) {
    <div class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2">
      <dl class="msa-text tw-grid tw-grid-cols-1 tw-gap-9 d:tw-grid-cols-3 d:tw-gap-30 d:tw-pe-30">
        <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
          {{ "i18n.profile-settings-title.iban" | safeTranslate }}
        </dt>
        <dd class="d:tw-col-span-2">
          {{ bankAccountData()!.iban | mask: "AAAA AAAA AAAA AAAA AAAA A" }}
        </dd>
      </dl>
      @if (!bankAccountData()?.userIsAccountOwner) {
        <dl
          class="msa-text tw-grid tw-grid-cols-1 tw-gap-9 tw-border-black-light-3 max-d:tw-mt-15 max-d:tw-border-t max-d:tw-pt-15 d:tw-grid-cols-3 d:tw-gap-30 d:tw-border-s d:tw-ps-30"
        >
          <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
            {{ "i18n.profile-settings-title.ibanholder" | safeTranslate }}
          </dt>
          <dd class="d:tw-col-span-2">
            <p>{{ bankAccountHolderData()?.displayName }}</p>
            <p>{{ bankAccountHolderData()?.addressLine1 }}</p>
            @if (bankAccountHolderData()?.addressLine2) {
              <p>{{ bankAccountHolderData()?.addressLine2 }}</p>
            }
            <p>{{ bankAccountHolderData()?.zipCode }} {{ bankAccountHolderData()?.city }}</p>
          </dd>
        </dl>
      }
    </div>
  } @else {
    <p class="msa-text tw-text-black-light-5">{{ "i18n.profile-settings-title.no-iban" | safeTranslate }}</p>
  }
</msa-card>
