<msa-card
  [menuItems]="menuItems"
  footNote="i18n.profile-settings-title.user.person.data.footer"
  cardTitle="i18n.profile-settings-title.user.personal.data"
>
  @if (personalData()) {
    <div
      class="msa-text tw-grid tw-grid-cols-1 d:tw-grid-cols-2 [&_*]:tw-border-black-light-3 [&_dt]:tw-text-black-light-5.5"
    >
      <dl class="tw-grid tw-content-start d:tw-gap-y-18 d:tw-border-e d:tw-pe-30">
        <div class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.name" | safeTranslate }}
          </dt>
          <dd data-cy="firstName">
            <strong>{{ personalData().firstName }}</strong>
          </dd>
        </div>
        <div class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.lastname" | safeTranslate }}
          </dt>
          <dd data-cy="lastName">
            <strong>{{ personalData().lastName }}</strong>
          </dd>
        </div>
        <div class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.gender.text" | safeTranslate }}
          </dt>
          <dd data-cy="gender">
            {{ getGenderTranslation(personalData().gender) | safeTranslate }}
          </dd>
        </div>
        <div class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.birthdate" | safeTranslate }}
          </dt>
          <dd data-cy="birthdate">
            {{ personalData().birthdate | date: GERMAN_SHORT_DATE_FORMAT : "de-CH" }}
          </dd>
        </div>
        <div class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.ahv" | safeTranslate }}
          </dt>
          <dd data-cy="ahvNumber">
            {{ personalData().ahvNumber | ahvNumber }}
          </dd>
        </div>
      </dl>
      <dl class="tw-grid tw-content-start d:tw-gap-y-18 d:tw-ps-30">
        <div class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.address.text" | safeTranslate }}
          </dt>
          <dd data-cy="street">
            {{ personalData().street }}
          </dd>
        </div>
        <div class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.address.plz" | safeTranslate }}
          </dt>
          <dd data-cy="zipCity">{{ personalData().zipCode }} {{ personalData().city }}</dd>
        </div>
        <div class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.job" | safeTranslate }}
          </dt>
          <dd data-cy="occupation">
            {{ personalData().occupationCode?.descriptionDto | translateObject }}
          </dd>
        </div>
        <div class="tw-grid tw-grid-cols-1 max-d:tw-pb-15 d:tw-grid-cols-2">
          <dt class="max-d:tw-pb-9">
            {{ "i18n.profile-settings-title.user.homeTown" | safeTranslate }}
          </dt>
          <dd data-cy="homeTown">
            {{ personalData().homeTown }}
          </dd>
        </div>
      </dl>
    </div>
  } @else {
    <p class="msa-text-small">{{ "i18n.common.no-data" | safeTranslate }}</p>
  }
</msa-card>
