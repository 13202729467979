import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {Store} from '@ngxs/store';
import {MsaAutocompleteComponent} from '@shared/shared-module/components/msa-autocomplete/msa-autocomplete.component';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {FetchCodeListOccupation} from '@shared/shared-module/stores/actions/code-list.action';
import {UpdateOccupation} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {CodeListSelectors} from '@shared/shared-module/stores/selectors/code-list.selectors';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {map, switchMap, tap} from 'rxjs';
import {OccupationDto} from '../../core/api/generated/msa-person-data';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, ReactiveFormsModule, MsaAutocompleteComponent, SafeTranslatePipe],
  selector: 'msa-occupation-edit-dialog',
  standalone: true,
  templateUrl: './occupation-edit-dialog.component.html'
})
export class OccupationEditDialogComponent implements OnInit {
  private store: Store = inject(Store);
  private destroyRef: DestroyRef = inject(DestroyRef);

  occupationCodeList = readStoreSignal(CodeListSelectors.getOccupationAutocompleteList);
  occupation = readStoreSignal(PersonDataStateSelectors.getOccupation);

  occupationForm: FormGroup = new FormGroup({
    occupationCode: new FormControl()
  });

  constructor() {
    this.store.dispatch(new FetchCodeListOccupation());
  }

  ngOnInit() {
    this.store
      .select(PersonDataStateSelectors.getOccupation)
      .pipe(
        switchMap((occupationCodeHashCurrentValue: string) =>
          this.store
            .select(CodeListSelectors.getOccupationAutocompleteList)
            .pipe(map(options => options.filter(item => item.id === occupationCodeHashCurrentValue)))
        ),
        tap(options => {
          if (options.length > 0) {
            this.occupationForm.patchValue({occupationCode: options[0]});
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  updateOccupation($event: DialogConfirmEvent): void {
    const occupationDto: OccupationDto = {
      occupationCodeHash: this.occupationForm.value.occupationCode.id
    };
    this.store.dispatch(new UpdateOccupation(occupationDto)).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: $event.resolve,
      error: $event.reject
    });
  }
}
