<div
  class="tw-mb-60 tw-mt-30 tw-flex tw-flex-col tw-gap-30 d:tw-mb-120 d:tw-mt-60 d:tw-gap-60"
  [attr.data-cy]="'profileSettings'"
>
  <h1 class="msa-text-h1">
    {{ "i18n.user.profile-settings" | safeTranslate }}
  </h1>

  @if (errorMessage()) {
    <app-msa-content-notice type="Warning">
      {{ errorMessage()! | translate }}
    </app-msa-content-notice>
  }

  <msa-personal-data />
  <msa-communication-data />
  <msa-bank-data />
  <app-swiss-pass *appFeatureFlag="'swissPass'" />
  <msa-emergency-contacts *appFeatureFlag="'emergencyContacts'" />
  <app-notification-data />
  <app-agreement-data />
</div>
