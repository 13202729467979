import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {AuthenticationService} from '@shared/core/services/authentication.service';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {AgreementService} from '@shared/shared-module/services/agreement/agreement.service';
import {tap} from 'rxjs';
import {DutyRestService, PersonRestService} from '../../core/api/generated/msa-person-data';
import {isDefined} from '@shared/shared-module/utils/is-defined';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, SafeTranslatePipe, MsaContentNoticeComponent],
  selector: 'msa-agreement-edit-dialog',
  standalone: true,
  templateUrl: './agreement-edit-dialog.component.html'
})
export class AgreementEditDialogComponent {
  protected readonly isDefined = isDefined;

  private readonly agreementService = inject(AgreementService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly dutyRestService = inject(DutyRestService);
  private readonly personRestService = inject(PersonRestService);

  requestStatus = toSignal(this.dutyRestService.getRequestStatus());
  dirtyStateStatus = toSignal(this.personRestService.getDirtyStateStatus());
  canCancelAgreement = computed(() => {
    if (isDefined(this.requestStatus()) && isDefined(this.dirtyStateStatus())) {
      return (this.requestStatus()!.hasOpenRequests || this.dirtyStateStatus()!.hasEntitiesInDirtyState) ?? true;
    } else {
      return true;
    }
  });

  updateAgreement($event: DialogConfirmEvent) {
    this.agreementService
      .updateAgreement(false)
      .pipe(tap(() => this.authenticationService.logout()))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: $event.resolve,
        error: $event.reject
      });
  }
}
