import {NgOptimizedImage} from '@angular/common';
import {ChangeDetectionStrategy, Component, effect, inject, signal, WritableSignal} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngxs/store';
import {MenuItem, MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {FetchSwissPass} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {AppStateSelectors} from '@shared/shared-module/stores/selectors/app.state.selectors';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {ConnectSwissPassDialogComponent} from '../../dialogs/connect-swiss-pass-dialog/connect-swiss-pass-dialog.component';
import {DisconnectSwissPassDialogComponent} from '../../dialogs/disconnect-swiss-pass-dialog/disconnect-swiss-pass-dialog.component';
import {MsaArrowLinkComponent} from '@shared/shared-module/components/msa-arrow-link/msa-arrow-link.component';

@Component({
  selector: 'app-swiss-pass',
  standalone: true,
  imports: [
    MsaCardComponent,
    SafeTranslatePipe,
    NgOptimizedImage,
    MatButtonModule,
    MatIconModule,
    MsaArrowLinkComponent
  ],
  templateUrl: './swiss-pass.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwissPassComponent {
  private store = inject(Store);
  private matDialog: MatDialog = inject(MatDialog);
  currentLanguage = readStoreSignal(AppStateSelectors.slices.language);
  swissPass = readStoreSignal(PersonDataStateSelectors.getSwissPass);
  menuItems: WritableSignal<Array<MenuItem>> = signal([]);

  constructor() {
    this.store.dispatch(FetchSwissPass);
    effect(
      () => {
        this.menuItems.set([
          this.swissPass()
            ? {
                menuItemTitle: 'i18n.person-data.dialogs.disconnect-swiss-pass.title',
                component: DisconnectSwissPassDialogComponent
              }
            : {
                menuItemTitle: 'i18n.person-data.dialogs.connect-swiss-pass.title',
                component: ConnectSwissPassDialogComponent
              }
        ]);
      },
      {allowSignalWrites: true}
    );
  }

  connectSwissPass() {
    this.matDialog.open(ConnectSwissPassDialogComponent);
  }
}
