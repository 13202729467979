@if (historyMilitaryData$ | async; as historyData) {
  <h2 class="msa-text-h2 tw-mb-12 d:tw-mb-18">{{ "i18n.navigation.item.military-career" | safeTranslate }}</h2>
  <div class="d:tw-px-[86.5px]">
    <msa-timeline [timelineData]="historyData" [timelineItemTemplate]="militaryHistoryCardTemplate">
      <ng-template #militaryHistoryCardTemplate let-data>
        <div class="tw-flex tw-flex-row">
          <div
            class="tw-flex-grow tw-p-15 d:tw-px-30 d:tw-pb-30 d:tw-pt-24"
            [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-bg-black-light-1' : 'tw-bg-rg-black-light-1'"
          >
            <p
              class="msa-text-small tw-mb-6"
              [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-text-black-light-5.5' : 'tw-text-black-light-4'"
              data-cy="date"
            >
              {{ data.effectiveFrom | date: GERMAN_SHORT_DATE_FORMAT }}
            </p>
            <h4
              class="msa-text tw-mb-9 d:tw-mb-18"
              [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-text-white' : 'tw-text-black-light-5'"
              data-cy="title"
            >
              <strong>
                {{ getTitle(data.rank, data.division, data.militaryFunction, data.rankAddition) | safeTranslate }}
              </strong>
            </h4>
            <dl class="msa-text-small tw-flex tw-flex-col tw-gap-y-6 d:tw-gap-y-12">
              @if (data.rank) {
                <div class="tw-flex tw-gap-x-12">
                  <dt
                    [class]="
                      isDateBeforeToday(data.effectiveFrom) ? 'tw-text-black-light-5.5' : 'tw-text-black-light-4'
                    "
                  >
                    {{ "i18n.military-history.new-rank-label" | safeTranslate }}:
                  </dt>
                  <dd
                    [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-text-white' : 'tw-text-black-light-5'"
                    data-cy="rank"
                  >
                    {{ data.rank.descriptionDto | translateObject }}
                  </dd>
                </div>
              }
              @if (data.rankAddition === RankAddition.J) {
                <div class="tw-flex tw-gap-x-12">
                  <dt
                    [class]="
                      isDateBeforeToday(data.effectiveFrom) ? 'tw-text-black-light-5.5' : 'tw-text-black-light-4'
                    "
                  >
                    {{ "i18n.military-history.rank-addition-label" | safeTranslate }}:
                  </dt>
                  <dd
                    [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-text-white' : 'tw-text-black-light-5'"
                    data-cy="rank-addition"
                  >
                    {{ "i18n.user.banner.rank-addition.j" | safeTranslate }}
                  </dd>
                </div>
              }
              @if (data.division) {
                <div class="tw-flex tw-gap-x-12">
                  <dt
                    [class]="
                      isDateBeforeToday(data.effectiveFrom) ? 'tw-text-black-light-5.5' : 'tw-text-black-light-4'
                    "
                  >
                    {{ "i18n.military-history.division-label" | safeTranslate }}:
                  </dt>
                  <dd
                    [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-text-white' : 'tw-text-black-light-5'"
                    data-cy="division"
                  >
                    {{ data.division.descriptionDto | translateObject }}
                  </dd>
                </div>
              }
              @if (data.militaryFunction) {
                <div class="tw-flex tw-gap-x-12">
                  <dt
                    [class]="
                      isDateBeforeToday(data.effectiveFrom) ? 'tw-text-black-light-5.5' : 'tw-text-black-light-4'
                    "
                  >
                    {{ "i18n.military-history.function-label" | safeTranslate }}:
                  </dt>
                  <dd
                    [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-text-white' : 'tw-text-black-light-5'"
                    data-cy="function"
                  >
                    {{ data.militaryFunction.descriptionDto | translateObject }}
                  </dd>
                </div>
              }
              @if (data.troop) {
                <div class="tw-flex tw-gap-x-12">
                  <dt
                    [class]="
                      isDateBeforeToday(data.effectiveFrom) ? 'tw-text-black-light-5.5' : 'tw-text-black-light-4'
                    "
                  >
                    {{ "i18n.military-history.troop-label" | safeTranslate }}:
                  </dt>
                  <dd
                    [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-text-white' : 'tw-text-black-light-5'"
                    data-cy="troop"
                  >
                    {{ data.troop.descriptionDto | translateObject }}
                  </dd>
                </div>
              }
            </dl>
          </div>
          @if (data.icon) {
            <div
              class="tw-grid tw-w-60 tw-flex-shrink-0 tw-place-content-center d:tw-w-[110px]"
              [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-bg-olive-dark-2' : 'tw-bg-rg-olive-dark-2'"
            >
              <mat-icon
                class="!tw-h-auto !tw-w-48"
                [svgIcon]="data.icon"
                [class]="isDateBeforeToday(data.effectiveFrom) ? 'tw-text-white' : 'tw-text-black-light-4'"
              />
            </div>
          }
        </div>
      </ng-template>
    </msa-timeline>
  </div>
}
