import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {createValidationError} from '@shared/shared-module/utils/validator.utils';

export function ibanValidator(): ValidatorFn {
  const PHONE_VALIDATOR_KEY = 'invalidIban';
  return (control: AbstractControl): ValidationErrors | null => {
    const ibanRaw = control.value;
    if (!ibanRaw) {
      return null;
    }
    const iban = String(ibanRaw)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');
    if (!/^(CH|LI)/.test(iban)) {
      return {
        [PHONE_VALIDATOR_KEY]: createValidationError('i18n.person-data.dialogs.bank-edit.validation.invalid-country')
      };
    }
    if (iban.length !== 21) {
      return {
        [PHONE_VALIDATOR_KEY]: createValidationError('i18n.person-data.dialogs.bank-edit.validation.invalid-length')
      };
    }
    if (!validateIbanChecksum(iban)) {
      return {
        [PHONE_VALIDATOR_KEY]: createValidationError('i18n.person-data.dialogs.bank-edit.validation.invalid-checksum')
      };
    }
    return null;
  };
}

function validateIbanChecksum(iban: string): boolean {
  const rearranged = iban.slice(4) + iban.slice(0, 4);
  const numericIban = rearranged.replace(/[A-Z]/g, letter => (letter.charCodeAt(0) - 55).toString());
  return Number(BigInt(numericIban) % BigInt(97)) === 1;
}
