import {ChangeDetectionStrategy, Component, inject, signal} from '@angular/core';
import {ContactLinksComponent} from '../../components/contact-links/contact-links.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {SuperiorContactComponent} from '../../components/superior-contact/superior-contact.component';
import {MilitaryRestService, PersonMilitarySuperiorDataDto} from '../../core/api/generated/msa-person-data';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContactLinksComponent, SafeTranslatePipe, SuperiorContactComponent],
  selector: 'msa-contacts',
  standalone: true,
  templateUrl: './contacts.component.html'
})
export class ContactsComponent {
  private militaryRestService: MilitaryRestService = inject(MilitaryRestService);
  divisionSuperiorContact = signal<PersonMilitarySuperiorDataDto | null>(null);

  constructor() {
    this.militaryRestService.getSuperiorDataBySecurityContext().subscribe(result => {
      this.divisionSuperiorContact.set(result);
    });
  }
}
