<msa-card [cardTitle]="'i18n.contacts.ContactLinks.title'">
  <div class="msa-text -tw-my-18">
    <div
      class="tw-hidden tw-grid-cols-3 tw-gap-30 tw-border-b tw-border-black-light-4 tw-py-18 tw-text-black-light-5.5 d:tw-grid"
    >
      <div class="tw-col-span-1">{{ "i18n.contacts.ContactLinks.title" | safeTranslate }}</div>
      <div class="tw-col-span-2">{{ "i18n.contacts.ContactLinks.link" | safeTranslate }}</div>
    </div>
    @for (contact of contactList; track $index) {
      <div
        class="tw-grid tw-gap-15 tw-border-b tw-border-black-light-4 tw-pb-18 tw-pt-24 last:tw-border-b-0 d:tw-grid-cols-3 d:tw-gap-30 d:tw-pt-18"
      >
        <div class="tw-col-span-1">
          <strong>
            {{ contact.description | safeTranslate }}
          </strong>
        </div>
        <div class="tw-col-span-1 d:tw-col-span-2">
          <msa-arrow-link [label]="contact.linkText" [externalLink]="contact.link | safeTranslate" />
        </div>
      </div>
    }
  </div>
</msa-card>
