import {ChangeDetectionStrategy, Component, DestroyRef, effect, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {UpdateAndFetchPersonPreferences} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {PreferenceDtoNameEnum, PreferencesDto} from '../../core/api/generated/msa-person-data';
import {EmailEditDialogComponent} from '../email-edit-dialog/email-edit-dialog.component';
import {PhoneEditDialogComponent} from '../phone-edit-dialog/phone-edit-dialog.component';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MatIconModule} from '@angular/material/icon';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {MsaArrowLinkComponent} from '@shared/shared-module/components/msa-arrow-link/msa-arrow-link.component';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MsaDialogComponent,
    MsaContentNoticeComponent,
    SafeTranslatePipe,
    MatIconModule,
    ReactiveFormsModule,
    MsaCheckboxComponent,
    MsaArrowLinkComponent,
    MsaButtonComponent
  ],
  selector: 'msa-notification-edit-dialog',
  standalone: true,
  templateUrl: './notification-edit-dialog.component.html'
})
export class NotificationEditDialogComponent {
  private store: Store = inject(Store);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private matDialog: MatDialog = inject(MatDialog);
  notificationPreferences = readStoreSignal(PersonDataStateSelectors.getNotificationSettingsData);

  notificationsSettingsForm = new FormGroup({
    portal: new FormControl<boolean>({value: true, disabled: true}),
    email: new FormControl<boolean>({
      value: this.notificationPreferences()?.email?.enabled ?? false,
      disabled: !this.notificationPreferences()?.email?.address
    }),
    sms: new FormControl<boolean>({
      value: this.notificationPreferences()?.sms?.enabled ?? false,
      disabled: !this.notificationPreferences()?.sms?.number
    })
  });

  constructor() {
    effect(() => {
      if (this.notificationPreferences()?.email?.address) {
        this.notificationsSettingsForm.controls.email.enable();
      } else {
        this.notificationsSettingsForm.controls.email.disable();
      }
    });
    effect(() => {
      if (this.notificationPreferences()?.sms?.number) {
        this.notificationsSettingsForm.controls.sms.enable();
      } else {
        this.notificationsSettingsForm.controls.sms.disable();
      }
    });
  }

  updateNotificationSettings($event: DialogConfirmEvent) {
    const preferencesDto: PreferencesDto = {
      preferences: [
        {
          name: PreferenceDtoNameEnum.Sms,
          value: this.notificationsSettingsForm.controls.sms?.value ? 'true' : 'false'
        },
        {
          name: PreferenceDtoNameEnum.Email,
          value: this.notificationsSettingsForm.controls.email?.value ? 'true' : 'false'
        }
      ]
    };
    this.store
      .dispatch(new UpdateAndFetchPersonPreferences(preferencesDto))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: $event.resolve,
        error: $event.reject
      });
  }

  openEmailEditDialog() {
    this.matDialog.open(EmailEditDialogComponent);
  }

  openPhoneEditDialog() {
    this.matDialog.open(PhoneEditDialogComponent);
  }
}
