import {Pipe, PipeTransform} from '@angular/core';
import {isDefined} from '../utils/is-defined';

@Pipe({
  name: 'ahvNumber',
  standalone: true,
  pure: true
})
export class AhvNumberPipe implements PipeTransform {
  transform(ahv: string | undefined | null): string {
    // Check if the input value is a valid string
    return formatAhvNumber(ahv);
  }
}

export function formatAhvNumber(ahv: string | undefined | null): string {
  if (isDefined(ahv)) {
    const separator = '.';
    const ahvFormatted = [
      ahv.slice(0, 3),
      separator,
      ahv.slice(3, 7),
      separator,
      ahv.slice(7, 11),
      separator,
      ahv.slice(11, 13)
    ].join('');
    return ahvFormatted;
  } else {
    return '';
  }
}
