import {ChangeDetectionStrategy, Component, DestroyRef, inject} from '@angular/core';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {Store} from '@ngxs/store';
import {DeleteSwissPass} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, SafeTranslatePipe],
  selector: 'msa-disconnect-swiss-pass-dialog',
  standalone: true,
  templateUrl: './disconnect-swiss-pass-dialog.component.html'
})
export class DisconnectSwissPassDialogComponent {
  private store: Store = inject(Store);
  private destroyRef: DestroyRef = inject(DestroyRef);

  disconnectSwissPass($event: DialogConfirmEvent) {
    this.store.dispatch(DeleteSwissPass).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: $event.resolve,
      error: $event.reject
    });
  }
}
