import {ChangeDetectionStrategy, Component, effect, inject, signal} from '@angular/core';
import {MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MatIconModule} from '@angular/material/icon';
import {TranslateService} from '@ngx-translate/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {MsaArrowLinkComponent} from '@shared/shared-module/components/msa-arrow-link/msa-arrow-link.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaCardComponent, SafeTranslatePipe, MatIconModule, MsaArrowLinkComponent],
  selector: 'msa-contact-links',
  standalone: true,
  templateUrl: './contact-links.component.html'
})
export class ContactLinksComponent {
  private translateService: TranslateService = inject(TranslateService);
  private langChange = toSignal(this.translateService.onLangChange);
  protected currentLang = signal(this.translateService.currentLang);

  contactList: {
    description: TranslationString;
    link: TranslationString;
    linkText: TranslationString;
    [key: string]: string;
  }[] = [
    {
      description: 'i18n.contacts.ContactLinks.cantonalMilitaryAuthorities',
      linkText: 'i18n.contacts.ContactLinks.cantonalMilitaryAuthoritiesLinkText',
      link: 'i18n.contacts.ContactLinks.cantonalMilitaryAuthoritiesLink'
    },
    {
      description: 'i18n.contacts.ContactLinks.hotlinePersA',
      linkText: 'i18n.contacts.ContactLinks.hotlinePersALinkText',
      link: 'i18n.contacts.ContactLinks.hotlinePersALink'
    },
    {
      description: 'i18n.contacts.ContactLinks.officeSwitzerland',
      linkText: 'i18n.contacts.ContactLinks.officeSwitzerlandLinkText',
      link: 'i18n.contacts.ContactLinks.officeSwitzerlandLink'
    },
    {
      description: 'i18n.contacts.ContactLinks.extremismAdviceCenter',
      linkText: 'i18n.contacts.ContactLinks.extremismAdviceCenterLinkText',
      link: 'i18n.contacts.ContactLinks.extremismAdviceCenterLink'
    },
    {
      description: 'i18n.contacts.ContactLinks.womenDiversityAdviceCenter',
      linkText: 'i18n.contacts.ContactLinks.womenDiversityAdviceCenterLinkText',
      link: 'i18n.contacts.ContactLinks.womenDiversityAdviceCenterLink'
    },
    {
      description: 'i18n.contacts.ContactLinks.socialServiceAdviceCenter',
      linkText: 'i18n.contacts.ContactLinks.socialServiceAdviceCenterLinkText',
      link: 'i18n.contacts.ContactLinks.socialServiceAdviceCenterLink'
    },
    {
      description: 'i18n.contacts.ContactLinks.psychologicalEducationalCounseling',
      linkText: 'i18n.contacts.ContactLinks.psychologicalEducationalCounselingLinkText',
      link: 'i18n.contacts.ContactLinks.psychologicalEducationalCounselingLink'
    },
    {
      description: 'i18n.contacts.ContactLinks.armyChaplaincy',
      linkText: 'i18n.contacts.ContactLinks.armyChaplaincyLinkText',
      link: 'i18n.contacts.ContactLinks.armyChaplaincyLink'
    },
    {
      description: 'i18n.contacts.ContactLinks.independentArmyTrustOffice',
      linkText: 'i18n.contacts.ContactLinks.independentArmyTrustOfficeLinkText',
      link: 'i18n.contacts.ContactLinks.independentArmyTrustOfficeLink'
    }
  ];

  constructor() {
    effect(
      () => {
        this.currentLang.set(this.langChange()?.lang ?? this.currentLang());
      },
      {allowSignalWrites: true}
    );
  }
}
