import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MenuItem, MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {NotificationEditDialogComponent} from '../../dialogs/notification-edit-dialog/notification-edit-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaCardComponent, MatIconModule, SafeTranslatePipe],
  selector: 'app-notification-data',
  standalone: true,
  templateUrl: './notification-data.component.html'
})
export class NotificationDataComponent {
  menuItems: Array<MenuItem> = [
    {component: NotificationEditDialogComponent, menuItemTitle: 'i18n.profile-settings.notification.dialog.title'}
  ];

  notificationDataByType = readStoreSignal(PersonDataStateSelectors.getNotificationByType);
}
