<msa-card [menuItems]="menuItems" cardTitle="i18n.profile-settings-title.user.notifications.agreement">
  <div class="msa-text tw-grid max-d:tw-gap-y-9 d:tw-grid-cols-4 d:tw-gap-x-30">
    <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
      {{ "i18n.profile-settings-title.user.notifications.agreement" | safeTranslate }}
    </dt>
    <dd class="tw-flex tw-gap-x-6 d:tw-col-span-3">
      <mat-icon class="!tw-size-24 tw-flex-shrink-0" svgIcon="check" />
      <span>{{ "i18n.profile-settings-title.user.notifications.message" | safeTranslate }}</span>
    </dd>
  </div>
</msa-card>
