import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {FeatureFlagDirective} from '@shared/shared-module/directives/feature-flag.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {
  FetchPendingRequests,
  FetchPersonData,
  FetchPersonPreferences
} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {AgreementDataComponent} from '../../components/agreement-data/agreement-data.component';
import {BankDataComponent} from '../../components/bank-data/bank-data.component';
import {CommunicationDataComponent} from '../../components/communication-data/communication-data.component';
import {EmergencyContactsComponent} from '../../components/emergency-contacts/emergency-contacts.component';
import {NotificationDataComponent} from '../../components/notification-data/notification-data.component';
import {PersonalDataComponent} from '../../components/personal-data/personal-data.component';
import {SwissPassComponent} from '../../components/swiss-pass/swiss-pass.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SafeTranslatePipe,
    PersonalDataComponent,
    CommunicationDataComponent,
    BankDataComponent,
    SwissPassComponent,
    FeatureFlagDirective,
    EmergencyContactsComponent,
    NotificationDataComponent,
    AgreementDataComponent,
    MsaContentNoticeComponent,
    TranslateModule
  ],
  selector: 'app-person-data',
  standalone: true,
  templateUrl: './person-data.component.html'
})
export class PersonDataComponent implements OnInit {
  private store = inject(Store);
  errorMessage = readStoreSignal(PersonDataStateSelectors.slices.errorMessage);

  ngOnInit(): void {
    this.store.dispatch(new FetchPersonData());
    this.store.dispatch(new FetchPendingRequests());
    this.store.dispatch(new FetchPersonPreferences());
  }
}
