import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject} from '@angular/core';
import {MenuItem, MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {AhvNumberPipe} from '@shared/shared-module/pipes/ahv.pipe';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {GERMAN_SHORT_DATE_FORMAT} from '@shared/shared-module/utils/date-time.utils';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {PersonCivilDataDtoGenderEnum} from '../../core/api/generated/msa-person-data';
import {OccupationEditDialogComponent} from '../../dialogs/occupation-edit-dialog/occupation-edit-dialog.component';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaCardComponent, SafeTranslatePipe, AhvNumberPipe, TranslateObjectPipe, DatePipe],
  selector: 'msa-personal-data',
  standalone: true,
  templateUrl: './personal-data.component.html'
})
export class PersonalDataComponent {
  protected readonly GERMAN_SHORT_DATE_FORMAT = GERMAN_SHORT_DATE_FORMAT;
  private featureFlagService = inject(FeatureFlagService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  personalData = readStoreSignal(PersonDataStateSelectors.getPersonalData);
  menuItems: Array<MenuItem> = [];

  constructor() {
    effect(() => {
      if (this.featureFlagService.hasFeaturesEnabled('occupation')()) {
        this.menuItems = [
          ...this.menuItems,
          {
            component: OccupationEditDialogComponent,
            menuItemTitle: 'i18n.person-data.dialogs.occupation-edit.title'
          }
        ];
      } else {
        this.menuItems = this.menuItems.filter(item => item.component !== OccupationEditDialogComponent);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  getGenderTranslation(gender: PersonCivilDataDtoGenderEnum | undefined): TranslationString {
    switch (gender) {
      case PersonCivilDataDtoGenderEnum.F:
        return 'i18n.profile-settings-title.user.gender.f';
      case PersonCivilDataDtoGenderEnum.M:
        return 'i18n.profile-settings-title.user.gender.m';
      default:
        return 'i18n.common.null';
    }
  }
}
