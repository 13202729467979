import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MatIconModule} from '@angular/material/icon';
import {
  PersonMilitarySuperiorDataDto,
  PersonMilitarySuperiorDataDtoGenderEnum,
  RankAddition
} from '../../core/api/generated/msa-person-data';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaCardComponent, SafeTranslatePipe, MatIconModule, TranslateObjectPipe],
  selector: 'msa-superior-contact',
  standalone: true,
  templateUrl: './superior-contact.component.html'
})
export class SuperiorContactComponent {
  superiorContact = input.required<PersonMilitarySuperiorDataDto>();
  titleText = input.required<TranslationString>();
  protected readonly RankAddition = RankAddition;
  protected readonly PersonMilitarySuperiorDataDtoGenderEnum = PersonMilitarySuperiorDataDtoGenderEnum;
}
