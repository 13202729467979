<msa-card [menuItems]="menuItems" cardTitle="i18n.profile-settings-title.user.notifications.text">
  @if (notificationDataByType()) {
    <div
      class="msa-text tw-grid tw-grid-cols-10 tw-items-center tw-pb-9 tw-text-black-light-5.5 max-d:tw-pt-24 d:tw-grid-cols-4 d:tw-gap-x-30 d:tw-pb-18"
    >
      <div class="tw-col-span-7 d:tw-col-span-1">
        {{ "i18n.profile-settings-title.user.notification.table.messageType" | safeTranslate }}
      </div>
      <div class="tw-flex tw-gap-x-12">
        <mat-icon class="!tw-size-24 tw-flex-shrink-0" svgIcon="portal" />
        <span class="max-d:tw-hidden">
          {{ "i18n.profile-settings-title.user.notification.table.portal" | safeTranslate }}
        </span>
      </div>
      <div class="tw-flex tw-gap-x-12">
        <mat-icon class="!tw-size-24 tw-flex-shrink-0" svgIcon="mail" />
        <span class="max-d:tw-hidden">{{
          "i18n.profile-settings-title.user.notification.table.email" | safeTranslate
        }}</span>
      </div>
      <div class="tw-flex tw-gap-x-12">
        <mat-icon class="!tw-size-24 tw-flex-shrink-0" svgIcon="sms" />
        <span class="max-d:tw-hidden">{{
          "i18n.profile-settings-title.user.notification.table.sms" | safeTranslate
        }}</span>
      </div>
    </div>
    <div
      class="msa-text tw-grid tw-grid-cols-10 tw-items-center tw-border-t tw-border-black-light-3 tw-pt-9 d:tw-grid-cols-4 d:tw-gap-x-30 d:tw-pt-18"
    >
      <div class="tw-col-span-7 d:tw-col-span-1">
        {{ "i18n.profile-settings-title.user.notification.all-notifications" | safeTranslate }}
      </div>
      <div>
        <mat-icon class="!tw-size-24" svgIcon="check" />
      </div>
      <div>
        <mat-icon
          class="!tw-size-24"
          [class.!tw-hidden]="!notificationDataByType().NOTIFICATION_RECEIVE_EMAIL"
          svgIcon="check"
        />
      </div>
      <div>
        <mat-icon
          class="!tw-size-24"
          [class.!tw-hidden]="!notificationDataByType().NOTIFICATION_RECEIVE_SMS"
          svgIcon="check"
        />
      </div>
    </div>
  }
</msa-card>
