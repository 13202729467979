<msa-card cardTitle="i18n.military-career.title">
  @if (currentMilitaryData(); as militaryData) {
    <div class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 [&_*]:tw-border-black-light-3">
      <!-- Left Column -->
      <dl class="msa-text tw-flex tw-flex-col d:tw-me-30 d:tw-gap-y-18 d:tw-border-e">
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
        >
          <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
            {{ "i18n.military-career.rank" | safeTranslate }}
          </dt>
          <dd class="tw-flex tw-items-center d:tw-col-span-2" data-cy="rank">
            @if (militaryData.rankIcon) {
              <div class="tw-me-6 tw-h-[50px] tw-max-w-[50px] d:tw-me-21">
                <mat-icon class="!tw-h-full !tw-w-auto" [svgIcon]="militaryData.rankIcon" />
              </div>
            }
            <span
              >{{ militaryData.rank | translateObject }}
              @if (militaryData.rankAddition) {
                {{ militaryData.rankAddition | safeTranslate }}
              }
            </span>
          </dd>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
        >
          <dt class="tw-col-span-1 tw-text-black-light-5.5">
            {{ "i18n.military-career.function" | safeTranslate }}
          </dt>
          <dd class="tw-col-span-2" data-cy="function">
            {{ militaryData.militaryFunction | translateObject }}
          </dd>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
        >
          <dt class="tw-col-span-1 tw-text-black-light-5.5">
            {{ "i18n.military-career.troop-type" | safeTranslate }}
          </dt>
          <dd class="tw-col-span-2 tw-flex tw-items-center" data-cy="troop">
            @if (militaryData.troopIcon) {
              <div class="tw-me-6 tw-h-[50px] tw-max-w-[50px] d:tw-me-21">
                <mat-icon class="!tw-h-full !tw-w-auto" [svgIcon]="militaryData.troopIcon" />
              </div>
            }
            <span>{{ militaryData.troop | translateObject }}</span>
          </dd>
        </div>
      </dl>
      <!-- Right Column -->
      <dl class="msa-text tw-flex tw-flex-col d:tw-ms-30 d:tw-gap-18">
        <div
          class="tw-grid tw-grid-cols-1 max-d:tw-mb-15 max-d:tw-gap-y-9 max-d:tw-border-b max-d:tw-pb-15 d:tw-grid-cols-3 d:tw-gap-x-30"
        >
          <dt class="tw-text-black-light-5.5 d:tw-col-span-1">
            {{ "i18n.military-career.division" | safeTranslate }}
          </dt>
          <dd class="d:tw-col-span-2" data-cy="division">
            {{ militaryData.division | translateObject }}
          </dd>
        </div>
        <div class="tw-grid tw-grid-cols-1 max-d:tw-gap-y-9 d:tw-grid-cols-3 d:tw-gap-x-30">
          <dt class="tw-col-span-1 tw-self-center tw-text-black-light-5.5">
            {{ "i18n.military-career.mobilization" | safeTranslate }}
          </dt>
          <dd class="tw-col-span-2" data-cy="high-availability">
            <!-- TODO: Update to msa-pill. -->
            @if (militaryData.highAvailability) {
              <msa-status-pill
                colorClass="tw-text-black"
                backgroundClass="tw-bg-vermillion-light-3"
                text="i18n.military-career.high-availability"
              />
            } @else {
              <msa-status-pill
                colorClass="tw-text-black"
                backgroundClass="tw-bg-tuscany-light-3"
                text="i18n.military-career.standard-availability"
              />
            }
          </dd>
        </div>
      </dl>
    </div>
  } @else {
    <p>
      {{ "i18n.person-data.inline-warning-message" | safeTranslate }}
    </p>
  }
</msa-card>
