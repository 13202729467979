<msa-dialog
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="occupationForm.invalid"
  [footNote]="'i18n.common.mandatory'"
  [title]="'i18n.person-data.dialogs.occupation-edit.title'"
  (confirmEvent)="updateOccupation($event)"
>
  <form [formGroup]="occupationForm">
    <p class="msa-text tw-mb-6">{{ "i18n.person-data.dialogs.occupation-edit.occupation" | safeTranslate }}</p>
    <msa-autocomplete [filterControl]="$any(occupationForm.get('occupationCode'))" [options]="occupationCodeList()!" />
  </form>
</msa-dialog>
