import {AsyncPipe, DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngxs/store';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {FetchMilitaryHistoryData} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {map} from 'rxjs';
import {CodeWithoutIconDto, PersonMilitaryHistoryDataDto, RankAddition} from '../../core/api/generated/msa-person-data';
import {MsaTimelineComponent} from '@shared/shared-module/components/msa-timeline/msa-timeline.component';
import {GERMAN_SHORT_DATE_FORMAT} from '@shared/shared-module/utils/date-time.utils';

interface GroupedEvents {
  [year: string]: PersonMilitaryHistoryDataDto[];
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaTimelineComponent, DatePipe, SafeTranslatePipe, TranslateObjectPipe, MatIconModule, AsyncPipe],
  selector: 'app-military-history',
  standalone: true,
  templateUrl: './military-history.component.html'
})
export class MilitaryHistoryComponent {
  protected readonly GERMAN_SHORT_DATE_FORMAT = GERMAN_SHORT_DATE_FORMAT;
  protected readonly RankAddition = RankAddition;

  private store: Store = inject(Store);

  historyMilitaryData$ = this.store
    .select(PersonDataStateSelectors.slices.militaryHistoryData)
    .pipe(map(historyMilitaryData => this.groupEventsByYear(historyMilitaryData)));

  constructor() {
    this.store.dispatch(new FetchMilitaryHistoryData());
  }

  private groupEventsByYear(events: PersonMilitaryHistoryDataDto[]): GroupedEvents {
    return events.reduce((groupedEvents: GroupedEvents, event) => {
      if (event.effectiveFrom) {
        const year = event.effectiveFrom.substring(0, 4);
        (groupedEvents[year] = groupedEvents[year] || []).push(event);
      }
      return groupedEvents;
    }, {});
  }

  public getTitle(
    rank: CodeWithoutIconDto | null,
    division: CodeWithoutIconDto | null,
    militaryFunction: CodeWithoutIconDto | null,
    rankAddition: RankAddition
  ) {
    if (rank?.descriptionDto?.de === 'Rekrut') {
      return 'i18n.military-history.recruitment';
    }

    if ((rank || rankAddition === RankAddition.J) && division && militaryFunction) {
      return 'i18n.military-history.function-division-rank-update';
    } else if ((rank || rankAddition === RankAddition.J) && division) {
      return 'i18n.military-history.rank-division-update';
    } else if ((rank || rankAddition === RankAddition.J) && militaryFunction) {
      return 'i18n.military-history.rank-function-update';
    } else if (militaryFunction && division) {
      return 'i18n.military-history.function-division-update';
    } else if (rank || rankAddition === RankAddition.J) {
      return 'i18n.military-history.rank-update';
    } else if (division) {
      return 'i18n.military-history.division';
    } else if (militaryFunction) {
      return 'i18n.military-history.military-function-update';
    }
    return 'i18n.military-history.division';
  }

  isDateBeforeToday(dateString: string) {
    const date = new Date(dateString);
    const today = new Date();
    return date < today;
  }
}
