<msa-dialog
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="false"
  [title]="'i18n.profile-settings.notification.dialog.title'"
  (confirmEvent)="updateNotificationSettings($event)"
>
  <div class="tw-mb-18">
    <app-msa-content-notice type="Info">
      {{ "i18n.profile-settings.notification.dialog.header-message" | safeTranslate }}
    </app-msa-content-notice>
  </div>
  <div
    class="msa-text tw-grid tw-grid-cols-10 tw-gap-9 tw-pb-9 tw-pt-15 tw-text-black-light-5.5 d:tw-gap-30 d:tw-pb-18 d:tw-pt-30"
  >
    <div class="tw-col-span-7 d:tw-col-span-2">
      {{ "i18n.profile-settings-title.user.notification.table.messageType" | safeTranslate }}
    </div>
    <div class="tw-flex tw-content-center tw-justify-center d:tw-col-span-2">
      <mat-icon class="tw-me-12 !tw-size-24 tw-flex-shrink-0" svgIcon="portal" />
      <div class="tw-hidden d:tw-block">
        <span>
          {{ "i18n.profile-settings-title.user.notification.table.portal" | safeTranslate }}
        </span>
      </div>
    </div>
    <div class="tw-flex tw-content-center tw-justify-center d:tw-col-span-3">
      <mat-icon
        class="tw-me-12 !tw-size-24 tw-flex-shrink-0"
        [class.max-d:tw-text-tuscany]="!notificationPreferences().email.address"
        svgIcon="mail"
      />
      <div class="tw-hidden d:tw-block">
        <span>{{ "i18n.profile-settings-title.user.notification.table.email" | safeTranslate }}: </span>
        @if (notificationPreferences().email.address) {
          <span>&nbsp;{{ notificationPreferences().email.address }}</span>
        } @else {
          <span class="tw-text-tuscany">
            &nbsp;{{ "i18n.profile-settings-title.user.notification.email-missing" | safeTranslate }}
          </span>
        }
      </div>
    </div>
    <div class="tw-flex tw-content-center tw-justify-center d:tw-col-span-3">
      <mat-icon
        class="tw-me-12 !tw-size-24 tw-flex-shrink-0"
        [class.max-d:tw-text-tuscany]="!notificationPreferences().sms.number"
        svgIcon="sms"
      />
      <div class="tw-hidden d:tw-block">
        <span>{{ "i18n.profile-settings-title.user.notification.table.sms" | safeTranslate }}: </span>
        @if (notificationPreferences().sms.number) {
          &nbsp;<span>{{ notificationPreferences().sms.number }}</span>
        } @else {
          &nbsp;<span class="tw-text-tuscany">
            {{ "i18n.profile-settings-title.user.notification.phone-missing" | safeTranslate }}
          </span>
        }
      </div>
    </div>
  </div>
  <form
    class="msa-text tw-grid tw-grid-cols-10 tw-items-center tw-gap-9 tw-border-y tw-border-y-black-light-4 tw-py-9 tw-text-white d:tw-gap-30 d:tw-py-30"
    [formGroup]="notificationsSettingsForm"
  >
    <div class="tw-col-span-7 d:tw-col-span-2">
      {{ "i18n.profile-settings-title.user.notification.all-notifications" | safeTranslate }}
    </div>
    <div class="tw-flex tw-content-center tw-justify-center d:tw-col-span-2">
      <msa-checkbox key="portal" formControlName="portal" />
    </div>
    <div class="tw-flex tw-content-center tw-justify-center d:tw-col-span-3">
      <msa-checkbox key="email" formControlName="email" />
    </div>
    <div class="tw-flex tw-content-center tw-justify-center d:tw-col-span-3">
      <msa-checkbox key="sms" formControlName="sms" />
    </div>
  </form>
  <div class="tw-mt-30 d:tw-mt-18">
    @if (notificationPreferences().email.address) {
      <div class="tw-relative tw-ps-30 d:tw-ps-0">
        <mat-icon class="tw-absolute tw-left-0 !tw-size-24 d:!tw-hidden" svgIcon="mail" />
        <p class="d:tw-hidden">
          <span>{{ "i18n.profile-settings-title.user.notification.email-text-mobile" | safeTranslate }}&nbsp;</span>
          <span>{{ notificationPreferences().email.address }}</span>
        </p>
        <msa-arrow-link
          (click)="openEmailEditDialog()"
          label="i18n.profile-settings-title.user.notification.change-email"
        />
      </div>
    } @else {
      <app-msa-content-notice type="Warning">
        <p
          class="tw-mb-6 d:tw-mb-9"
          [innerHTML]="'i18n.profile-settings-title.user.notification.email-notice' | safeTranslate"
        ></p>
        <msa-button (click)="openEmailEditDialog()" widthClass="tw-w-auto" color="secondary">
          {{ "i18n.profile-settings-title.user.notification.email-notice-button" | safeTranslate }}
        </msa-button>
      </app-msa-content-notice>
    }
  </div>
  <div class="tw-mt-15 d:tw-mt-18">
    @if (notificationPreferences().sms.number) {
      <div class="tw-relative tw-ps-30 d:tw-ps-0">
        <mat-icon class="tw-absolute tw-left-0 !tw-size-24 d:!tw-hidden" svgIcon="sms" />
        <p class="d:tw-hidden">
          <span>{{ "i18n.profile-settings-title.user.notification.phone-text-mobile" | safeTranslate }}&nbsp;</span>
          <span>{{ notificationPreferences().sms.number }}</span>
        </p>
        <msa-arrow-link
          (click)="openPhoneEditDialog()"
          label="i18n.profile-settings-title.user.notification.change-phone"
        />
      </div>
    } @else {
      <app-msa-content-notice type="Warning">
        <p
          class="tw-mb-6 d:tw-mb-9"
          [innerHTML]="'i18n.profile-settings-title.user.notification.phone-notice' | safeTranslate"
        ></p>
        <msa-button (click)="openPhoneEditDialog()" widthClass="tw-w-auto" color="secondary">
          {{ "i18n.profile-settings-title.user.notification.phone-notice-button" | safeTranslate }}
        </msa-button>
      </app-msa-content-notice>
    }
  </div>
</msa-dialog>
