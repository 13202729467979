import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MilitaryRestService} from '../../core/api/generated/msa-person-data';
import {MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {DatePipe} from '@angular/common';
import {GERMAN_SHORT_DATE_FORMAT} from '@shared/shared-module/utils/date-time.utils';
import {MatIconModule} from '@angular/material/icon';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {toSignal} from '@angular/core/rxjs-interop';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaCardComponent, TranslateObjectPipe, DatePipe, MatIconModule, SafeTranslatePipe],
  selector: 'msa-personal-status',
  standalone: true,
  templateUrl: './personal-status.component.html'
})
export class PersonalStatusComponent {
  protected readonly GERMAN_SHORT_DATE_FORMAT = GERMAN_SHORT_DATE_FORMAT;
  private militaryRestService = inject(MilitaryRestService);
  personalStatuses = toSignal(this.militaryRestService.getPersonalStatus());
}
